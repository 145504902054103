import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Form, Input, Select, TreeSelect, message, Modal, Pagination, Drawer, Tag,DatePicker } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import FileBoxModal from '../../components/fileBox/FileBoxModal'
import Editor from "../../components/editor/Index"
import { articleList, articleHandle, articleDel, articleVerify, articleCateList } from "../../api"
import { nanoid } from 'nanoid';
import AuthBtn from '../layout/AuthBtn';
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
import { useLangContext } from '../../utils/context'
import {getDate} from "../../utils/function"

const { confirm } = Modal;
const { Option } = Select;
function Article(props) {
	const { langConfig } = useLangContext();
	const [attrFlag, setattrFlag] = useState([]);
	const [verifyText, seVerifyText] = useState([]);
	const [publishDateTime,setPublishDateTime] = useState(getDate(0));

	useEffect(() => {
		if(localStorage.getItem("language") === "en"){
			setattrFlag([
				{ key: 'h', title: 'Headlines' },
				{ key: 'c', title: 'recommend' },
				// { key: 't', title: 'Topping' },
			])
			seVerifyText([{value:1,label:'pending'},{value:2,label:'pass'}])
		}else{
			setattrFlag([
				{ key: 'h', title: '头条' },
				{ key: 'c', title: '推荐' },
				// { key: 't', title: '置顶' },
			])
			seVerifyText([{value:1,label:'待审核'},{value:2,label:'已审核'}])
		}
    }, [])

	const getAttrFlag = (val) => {
		let flag_title = "";
		attrFlag.forEach(item => {
			if (item.key === val) {
				flag_title = item.title;
			}
		})
		return flag_title
	}
	const [drawerData, setDrawerData] = useState({})
	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
	const [loading, setLoading] = useState(true)
	const [cateData, setCateData] = useState([])
	const [editorData, setEditorData] = useState(""); // 编辑器内容改变时保存
	const [initEditorData, setInitEditorData] = useState(""); // 修改文章时保存获取到的初始内容
	const editorRef = useRef(null)

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		// console.log("onFinish", values);
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.g_thumb,
			dataIndex: 'thumb',
			width: 80,
			render: (text, record) => {
				return record.thumb && record.thumb.file_path ? <a href={record.thumb.file_path} target="_blank" rel="noreferrer"><img src={record.thumb.file_path} alt="" style={{ width: "50px", height: "50px" }} /></a> : ''
			},
		},
		{
			title: langConfig.g_title,
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: langConfig.g_category,
			dataIndex: 'cate_title',
			key: 'cate_title',
			width: 130,
		},
		{
			title: langConfig.article_attr,
			dataIndex: 'flag',
			key: 'flag',
			width: 80,
			render: (text, record) => {
				return getAttrFlag(record.flag)
			},
		},
		// {
		// 	title: langConfig.article_visit,
		// 	dataIndex: 'visit_count',
		// 	key: 'visit_count',
		// 	width: 80
		// },
		{
			title: langConfig.g_sort,
			dataIndex: 'sort',
			key: 'sort',
			width: 60
		},
		{
			title: langConfig.g_verify,
			dataIndex: 'is_verify',
			key: 'is_verify',
			width: 80,
			render: (text, record) => {
				return record.is_verify === 2 ? <Tag color="#2db7f5">{verifyText[1]['label']}</Tag> : <Tag color="#f50">{verifyText[0]['label']}</Tag>
			},
		},
		{
			title: langConfig.article_publisher,
			dataIndex: 'user_name',
			key: 'user_name',
			width: 100
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 105
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '170px',
			render: (text, record) => {
				return <div>
					<AuthBtn button='admin:article:save'>
						<Button type='link' size='small' onClick={() => drawerEditHandle(record)}>{langConfig.g_edit}</Button>
					</AuthBtn>
					<AuthBtn button='admin:article:del'>
						<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
					</AuthBtn>
					{
						record.is_verify !== 2 ? <AuthBtn button='admin:article:verify'><Button type='link' size='small' onClick={() => verifyData(record.id)}>{langConfig.g_verify}</Button></AuthBtn> : null
					}
				</div>
			},
		},
	]
	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				articleDel({ id: id }).then(res => {
					getData()
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}
	const verifyData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_verify,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,

			onOk() {
				articleVerify({ id: id }).then(res => {
					getData()
					message.success(langConfig.g_operate_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		articleList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page, pageInfo.page_size])

	useEffect(() => {
		articleCateList({ type: 2 }).then(res => {
			if (res.code === 200) {
				setCateData(res.data)
			}
		}).catch(err => {
			console.log(err)
		})
	}, [])

	const onPageChange = (val, page_size) => {
		setPageInfo({ page_size, page: val })
	}
	const onShowSizeChange = (current, page_size) => {
		setPageInfo({ page: 1, page_size })
	}

	const [drawerConfig, setDrawerConfig] = useState({ title: langConfig.g_add, visible: false, width: 900 });
	const drawerAddHandle = () => {
		editorRef.current = nanoid()
		form.resetFields();
		setDrawerData({})
		setThumbData([])
		setAttachData([])
		setInitEditorData("")
		setEditorData("");
		setPublishDateTime(null)
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_add, visible: true });
	};
	const drawerEditHandle = (row) => {
		console.log("row",row)
		setDrawerData(row)
		setInitEditorData(row.content)
		form.resetFields();
		form.setFieldsValue({ ...row })
		if (row.thumb) {
			setThumbData([row.thumb])
		} else {
			setThumbData([])
		}
		if (row.attach) {
			setAttachData([row.attach])
		} else {
			setAttachData([])
		}
		setInitEditorData(row.content || "")
		setEditorData(row.content || "");
		setPublishDateTime(row.created_at || "")
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_edit + " " + langConfig.article_bread_one, visible: true });
	};
	const drawerCloseHandle = () => {
		setDrawerConfig({ ...drawerConfig, visible: false });
	};
	// 文件管理配置
	const [fileBoxConfig, setFileBoxConfig] = useState({ visible: false, max_select_count: 1, width: 1000, fileboxKey: nanoid(), field: 'thumb' })
	const fileBoxHanlde = (obj) => {
		if (obj.type === 'ok') {
			if (fileBoxConfig.field === 'thumb') {
				setThumbData(obj.data)
			} else if (fileBoxConfig.field === 'attach') {
				setAttachData(obj.data)
			}
		}
		setFileBoxConfig({ ...fileBoxConfig, visible: false });
	}
	const showUploadThumbBox = (field) => {
		setFileBoxConfig({ ...fileBoxConfig, visible: true, fileboxKey: nanoid(), field });
	}
	const handleDelThumb = (obj) => {
		setThumbData(thumbData.filter((item => item !== obj)))
	}
	const [thumbData, setThumbData] = useState([])
	const uploadThumbCom = () => {
		if (thumbData.length < fileBoxConfig.max_select_count) {
			let tmp = thumbData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
			})
			return (<>{tmp}<li className='my-upload-box' onClick={() => { showUploadThumbBox('thumb') }}><PlusOutlined /></li></>)
		} else {
			return thumbData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
			})
		}
	}

	// 附件下载
	const [attachData, setAttachData] = useState([])
	const uploadAttachCom = () => {
		if (attachData.length < fileBoxConfig.max_select_count) {
			let tmp = attachData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer">{item.original_name}</a><span className='action-box' onClick={() => { handleDelAttach(item) }}>{langConfig.g_del}</span></li>
			})
			return (<>{tmp}<li style={{ width: '40px', height: '40px', lineHeight: '40px', textAlign: "center", border: "1px solid #f0f0f0", cursor: "pointer" }} onClick={() => { showUploadThumbBox('attach') }}><PlusOutlined /></li></>)
		} else {
			return attachData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer">{item.original_name}</a><span className='action-box' onClick={() => { handleDelAttach(item) }}>{langConfig.g_del}</span></li>
			})
		}
	}
	const handleDelAttach = (obj) => {
		setAttachData(attachData.filter((item => item !== obj)))
	}

	const handleEditorChange = (val) => {
		setEditorData(val)
	}

	// 文章发布时间
    const handlePublishChange = (value, dateString) => {
		console.log("dateString",dateString)
        setPublishDateTime(dateString)
    }

	const [form] = Form.useForm();
	const onFinish = (values) => {
		let thumb_id = "";
		if (thumbData.length > 0) {
			thumb_id = thumbData[0].id;
		}
		let attach_id = "";
		if (attachData.length > 0) {
			attach_id = attachData[0].id;
		}
		articleHandle({ ...values, thumb_id, attach_id, content: editorData, id: drawerData.id || "",publishDateTime }).then(res => {
			setDrawerConfig({ ...drawerConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})
	};
	const layout = {
		labelCol: {
			span: 5,
		},
		wrapperCol: {
			span: 18,
		},
	};

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.article_bread_one} / </span>{langConfig.article_bread_two}</div>
				<div className='breaccrumb-right'>
					<AuthBtn button='admin:article:save'>
						<Button type="primary" size='small' onClick={() => drawerAddHandle()}> <PlusOutlined />{langConfig.g_add}</Button>
					</AuthBtn>
				</div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="cate_id" label="">
						<TreeSelect placeholder={langConfig.g_category} allowClear treeData={cateData} style={{ width: '160px' }} />
					</Form.Item>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.g_title} />
					</Form.Item>
					<Form.Item name="flag">
						<Select allowClear placeholder={langConfig.article_attr} style={{ width: '120px' }}>
							{
								attrFlag.map(item => {
									return <Option key={item.key} value={item.key}>{item.title}</Option>
								})
							}
						</Select>
					</Form.Item>
					<Form.Item name="is_verify">
						<Select allowClear placeholder={langConfig.g_verify} style={{ width: '100px' }}>
							{
								verifyText.map(item => {
									return <Option key={item.value} value={item.value}>{item.label}</Option>
								})
							}
							{/* <Option value={1}>待审核</Option>
							<Option value={2}>已审核</Option> */}
						</Select>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination
					current={pageInfo.page}
					total={pageInfo.total}
					pageSize={pageInfo.page_size}
					onChange={(page, pageSize) => onPageChange(page, pageSize)}
					pageSizeOptions={[10, 30, 60, 100]}
					onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
				/>
			</div>

			<Drawer title={drawerConfig.title} placement="right" maskClosable={false} width={drawerConfig.width} visible={drawerConfig.visible} onClose={drawerCloseHandle}>
				<Form {...layout} form={form} onFinish={onFinish} layout="inline" className="my-inline-form">
					<Form.Item name="title" label={langConfig.g_title} style={{ width: "743px" }}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<Input allowClear style={{ width: "743px" }} />
					</Form.Item>
					<Form.Item ></Form.Item>
					<Form.Item name="cate_id" label={langConfig.g_category}
						rules={[
							{
								required: true,
								message: ''
							},
						]}
					>
						<TreeSelect allowClear treeData={cateData} treeDefaultExpandAll />
					</Form.Item>
					<Form.Item name="flag" label={langConfig.article_attr}>
						<Select allowClear placeholder="">
							{
								attrFlag.map(item => {
									return <Option key={item.key} value={item.key}>{item.title}</Option>
								})
							}
						</Select>
					</Form.Item>
					{/* <Form.Item name="jump_url" label="外部链接">
						<Input allowClear placeholder='http(s)://开头' />
					</Form.Item>
					<Form.Item name="seo_title" label="SEO标题">
						<Input allowClear placeholder='' />
					</Form.Item> */}
					<Form.Item name="seo_keyword" label={langConfig.g_keyword}>
						<Input allowClear placeholder='' />
					</Form.Item>
					<Form.Item name="sort" label={langConfig.g_sort} tooltip="降序，越大越靠前">
						<Input allowClear />
					</Form.Item>
					<Form.Item name="seo_desc" label={langConfig.g_desc}>
						<Input.TextArea allowClear placeholder='' rows={3} />
					</Form.Item>
					<Form.Item name="thumb_id" label={langConfig.g_thumb}>
						<div className='myupload-box-list'>
							{uploadThumbCom()}
						</div>
					</Form.Item>
					<div style={{ marginTop: '90px' }}></div>
					<Form.Item name="attach_id" label={langConfig.article_attach}>
						<div className='myupload-attach-list'>
							{uploadAttachCom()}
						</div>
					</Form.Item>
					<div style={{ width: "840px", margin: "0 0 15px 0", display: "flex" }}>
						<div style={{ width: "87px", textAlign: "right" }}>{langConfig.g_content}：</div>
						<div style={{ width: "740px" }}><Editor key={editorRef.current} value={initEditorData} onChange={handleEditorChange} /></div>
					</div>
					<Form.Item label={langConfig.article_created}>
						<DatePicker showTime value={publishDateTime?moment(publishDateTime !==null?publishDateTime:undefined, 'YYYY-MM-DD HH:mm'):''} onChange={handlePublishChange} inputReadOnly allowClear={false} style={{ width: "100%" }} />
					</Form.Item>
					<div style={{ marginTop: '10px', width: "100%", padding: "0px 0 0 87px" }}>
						<Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
					</div>
				</Form>
			</Drawer>
			<FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal>

		</div>
	)
}

export default Article