import React, { useState, useEffect } from 'react'
import { Tabs, Pagination, message, Select, Button, Form, Input } from 'antd';
import { SearchOutlined, } from '@ant-design/icons';
import FileImg from './FileImg';
import FileDocument from './FileDocument';
import FileAudio from './FileAudio';
import FileVideo from './FileVideo'
import { filesList } from "../../api"
import Upload from './Upload';

const { TabPane } = Tabs;
const { Option } = Select

function FileBox(props) {
    const [form] = Form.useForm();

    const { max_select_count = 300 } = props // 每次可选择的最大文件数
    const [dataSource, setDataSource] = useState({
        data: [],
        total: 0
    })

    const [uploadCateId, setUploadCateId] = useState("");
    const [tabActiveKey, setTabActiveKey] = useState(1);
    const [pagination, setPagination] = useState({ page: 1, page_size: 12 })
    const [selectedItem, setSelectedItem] = useState([])
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [tabActiveKey,pagination])

    const getData = () => {
        let formres = form.getFieldsValue()
        filesList({ keyword: formres.file_title || "", type: tabActiveKey, page: pagination.page, page_size: pagination.page_size }).then(res => {
            setDataSource(res.data)
        })
    }

    // 点击查询
    const handleQuerySubmit = async () => {
        getData();
    }
    // const onPageChange = (val) => {
    //     setPagination({ ...pagination, current: val })
    // }

    const onPageChange = (val, page_size) => {
        setPagination({ page_size, page: val })
    }
    const onShowSizeChange = (current, page_size) => {
        setPagination({ page: 1, page_size })
    }

    const callbackCate = (val) => {
        // console.log("callbackCate",val)
        setUploadCateId(val)
    }

    const tabFileType = [
        { type: 1, title: '图片' },
        { type: 2, title: '文件' },
        // { type: 3, title: '音频' },
        // { type: 3, title: '视频' },
        // { type: 4, title: '其它' },
    ];

    // 切换标签
    const onTabChange = (val) => {
        console.log("onTabChange",val)
        setTabActiveKey(val)
        setDataSource({
            data: [],
            total: 0
        })
        setPagination({ ...pagination, page: 1 })
        setSelectedItem([])
    }

    // 选中文件的回调
    const selectedCallBack = (obj) => {
        if (max_select_count === 1) { // 单选情况
            props.parentHandleSelFile([obj])
            setSelectedItem([obj])
        } else {
            let _selectedItem = selectedItem;
            if (_selectedItem.includes(obj)) {
                _selectedItem = _selectedItem.filter(v => v !== obj)
            } else {
                if (selectedItem.length >= max_select_count) {
                    message.error(`最多只允许选择 ${max_select_count} 个`)
                    return false;
                }
                _selectedItem.push(obj);
            }
            props.parentHandleSelFile(_selectedItem)
            setSelectedItem([..._selectedItem])
        }
    }

    // 渲染目标组件
    const tabTarget = () => {
        let _tabActiveKey = parseInt(tabActiveKey)
        if (_tabActiveKey === 1) {
            return <FileImg data={dataSource.data} selectedCallBack={(obj) => { selectedCallBack(obj) }} selectedItem={selectedItem} />
        } else if (_tabActiveKey === 2) {
            return <FileDocument data={dataSource.data} selectedCallBack={(val) => { selectedCallBack(val) }} selectedItem={selectedItem} />
        } else if (_tabActiveKey === 3) {
            return <FileVideo data={dataSource.data} selectedCallBack={(val) => { selectedCallBack(val) }} selectedItem={selectedItem} />
        } else if (_tabActiveKey === 4) {
            return <FileVideo data={dataSource.data} selectedCallBack={(val) => { selectedCallBack(val) }} selectedItem={selectedItem} />
        }
    }

    const refreshData = () => {
        // setPagination({ ...pagination, current: 1 })
        getData()
    }

    return (
        <div className="web-contaiter">
            <div className='tab-title'>
                <div className='tab-current-btn'>
                    <Upload refreshData={refreshData} type={tabActiveKey}
                    >上传{tabFileType[tabActiveKey - 1].title}</Upload>
                </div>
                <div className='tab-current-title'>
                    <Form form={form} layout="inline" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                        <Form.Item label="" name="file_title">
                            <Input allowClear placeholder="文件名称" style={{ width: '300px' }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={handleQuerySubmit} icon={<SearchOutlined />}>查询</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Tabs defaultActiveKey={tabActiveKey} onChange={(key) => { onTabChange(key) }}>
                {
                    tabFileType.map(item => {
                        return <TabPane tab={item.title} key={item.type}></TabPane>
                    })
                }
            </Tabs>

            <div className='tab-box-content tab-box-img'>
                <div className='tab-box-pannel' style={{ minHeight:'230px'}}>
                    {tabTarget()}
                </div>

                <div className="pagination-box file-box-page" style={{ display: "flex" }}>
                    <div>共 {dataSource.total} 条</div>

                    <Pagination
                        size="small"
                        current={pagination.page}
                        pageSize={pagination.page_size}
                        total={dataSource.total}
                        onChange={(page, pageSize) => onPageChange(page, pageSize)}
                        pageSizeOptions={[12, 36, 100]}
                        onShowSizeChange={(current, size) => onShowSizeChange(current, size)}
                    />
                </div>
            </div>
        </div>
    )
}

export default FileBox
