import React, { useState, useEffect,useRef } from 'react'
import { Table, Button, Form, Input, Select, TreeSelect, message, Modal, Pagination, Drawer, Switch, Tabs, Tag,Cascader } from "antd"
import { ExclamationCircleOutlined, SearchOutlined, PlusOutlined, DeleteOutlined, FileExcelOutlined,CloudUploadOutlined } from '@ant-design/icons';
import FileBoxModal from '../../components/fileBox/FileBoxModal'
import Editor from "../../components/editor/Index"
import { productList, productHandle, productDel, productOneSpec, productCateList, getProParams,productExportTpl, productImport,productVerify } from "../../api"
import { nanoid } from 'nanoid';
import ComRelatePro from './ComRelatePro';
import SpecTableCom from './SpecTableCom';
import AuthBtn from '../layout/AuthBtn';
import { useLangContext } from '../../utils/context'
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;
function Product() {
	const { langConfig } = useLangContext();
	const [drawerData, setDrawerData] = useState({})
	const [specData, setSpectData] = useState([]); // 父子组件通讯多规格数据
	const [initSpecData, setInitSpecData] = useState([]); // 默认的多规格数据【编辑时用】
	const [proParamsData, setProParamsData] = useState([]) // 所有分类的参数列表
	const [proItemParams, setProItemParams] = useState([]) // 单个分类的参数列表
	const editorRef = useRef(null)
	const [editorData, setEditorData] = useState(""); // 编辑器内容改变时保存
	const [initEditorData, setInitEditorData] = useState(""); // 修改文章时保存获取到的初始内容

	const [tableData, setTableData] = useState([])
	const [pageInfo, setPageInfo] = useState({ page: 1, page_size: 10, total: 0 })
	const [loading, setLoading] = useState(true)
	const [cateData, setCateData] = useState([])

	const refDom = useRef(null);
    const refExcelId = useRef(null);

	const [formQuery] = Form.useForm();
	const onQueryFinish = (values) => {
		if (pageInfo.page === 1) {
			getData()
		} else {
			setPageInfo({ ...pageInfo, page: 1 })
		}
	};

	const [verifyText, seVerifyText] = useState([]);
	const [saleText, seSaleText] = useState([]);

	useEffect(() => {
		if(localStorage.getItem("language") === "en"){
			seSaleText([{value:1,label:'undercarriage'},{value:2,label:'onsale'}])
			seVerifyText([{value:1,label:'pending'},{value:2,label:'pass'}])
		}else{
			seSaleText([{value:1,label:'下架'},{value:2,label:'上架'}])
			seVerifyText([{value:1,label:'待审核'},{value:2,label:'已审核'}])
		}
    }, [])

	// 表格模块
	const tableColumns = [
		{
			title: langConfig.g_thumb,
			dataIndex: 'thumb',
			width: '90px',
			render: (text, record) => {
				return record.thumb_default && record.thumb_default.file_path ? <a href={record.thumb_default.file_path} target="_blank" rel="noreferrer"><img src={record.thumb_default.file_path} alt="" style={{ width: "50px", height: "50px" }} /></a> : ''
			},
		},
		{
			title: langConfig.pro_title,
			dataIndex: 'title',
			key: 'title',
			width: 260,
			render: (text, record) => {
				return <a href={record.url} target="_blank">{record.title}</a>
			},
		},
		{
			title: langConfig.pro_cate,
			dataIndex: 'cate_title',
			key: 'cate_title',
		},
		{
			title: langConfig.pro_is_sale,
			dataIndex: 'is_sale',
			width: '105px',
			render: (text, record) => {
				// return record.is_sale === 1 ? <Tag color="#108ee9">上架</Tag> : <Tag color="#ff5500">下架</Tag>
				return record.is_sale === 1 ? <Tag color="#2db7f5">{saleText[1]['label']}</Tag> : <Tag color="#f50">{saleText[0]['label']}</Tag>
			},
		},
		{
			title: langConfig.pro_verify,
			dataIndex: 'is_verify',
			key: 'is_verify',
			width: 80,
			render: (text, record) => {
				return record.is_verify === 2 ? <Tag color="#2db7f5">{verifyText[1]['label']}</Tag> : <Tag color="#f50">{verifyText[0]['label']}</Tag>
			},
		},
		{
			title: langConfig.g_sort,
			dataIndex: 'sort',
			key: 'sort',
			width: 80
		},
		{
			title: langConfig.article_publisher,
			dataIndex: 'user_name',
			key: 'user_name',
			width: 100
		},
		{
			title: langConfig.g_create_at,
			dataIndex: 'created_at',
			key: 'created_at',
			width: 100
		},
		{
			title: langConfig.g_manage,
			key: 'Manage',
			width: '170px',
			render: (text, record) => {
				return <div>
					<AuthBtn button='admin:product:save'>
						<Button type='link' size='small' onClick={() => drawerEditHandle(record)}>{langConfig.g_edit}</Button>
					</AuthBtn>
					<AuthBtn button='admin:product:del'>
						<Button type='link' size='small' onClick={() => delData(record.id)}>{langConfig.g_del}</Button>
					</AuthBtn>
					<AuthBtn button='admin:product:verify'>
						{
							record.is_verify === 2 ?null:<Button type='link' size='small' onClick={() => verifyData(record.id)}>{langConfig.g_verify}</Button>
						}
						
					</AuthBtn>
				</div>
			},
		},
	]

	const delData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_del,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,
			onOk() {
				productDel({ id: id }).then(res => {
					getData()
					message.success(langConfig.g_del_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const verifyData = (id) => {
		confirm({
			title: langConfig.g_reminder,
			icon: <ExclamationCircleOutlined />,
			content: langConfig.g_comfirm_verify,
			cancelText: langConfig.g_cancel,
			okText: langConfig.g_ok,

			onOk() {
				productVerify({ id: id }).then(res => {
					getData()
					message.success(langConfig.g_operate_succ)
				}).catch(err => {
					console.log(err)
				})

			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const getData = () => {
		setLoading(true)
		productList({ ...pageInfo, ...formQuery.getFieldsValue() }).then(res => {
			if (res.code === 200) {
				setTableData(res.data.data)
				setPageInfo({ ...pageInfo, total: res.data.total })
				setLoading(false)
			}
		}).catch(err => {
			console.log(err)
			setLoading(false)
		})
	}
	useEffect(() => {
		getData();
	}, [pageInfo.page])

	useEffect(() => {
		productCateList({ type: 2 }).then(res => {
			if (res.code === 200) {
				setCateData(res.data)
			}
		}).catch(err => {
			console.log(err)
		})

		getProParams({}).then(res => {
			if (res.code === 200) {
				setProParamsData(res.data)
			}
		}).catch(err => {
			console.log(err)
		})
	}, [])

	// 分页设置
	const onPageChange = (page) => {
		setPageInfo({ ...pageInfo, page: page })
	}

	const [drawerConfig, setDrawerConfig] = useState({ title: '', visible: false, width: 1000 });
	const drawerAddHandle = () => {
		editorRef.current = nanoid()
		form.resetFields();
		form.setFieldsValue({ is_sale: true })
		setProItemParams([])
		setInitSpecData([])
		setDrawerData({})
		setThumbData([])
		setInitEditorData("")
		setEditorData("");
		setRelateProductData([])
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_add, visible: true });
	};
	const drawerEditHandle = (row) => {
		editorRef.current = nanoid()
		setDrawerData(row)
		setInitEditorData(row.pro_desc || "")
		setEditorData(row.pro_desc || "");
		setThumbData(row.thumbs)
		form.resetFields();
		setRelateProductData(row.relation_pro)
		form.setFieldsValue({ ...row, is_sale: row.is_sale === 1 ? true : false, is_show_price: row.is_show_price === 1 ? true : false })
		productOneSpec({ id: row.id, cate_id: row.cate_id }).then(res => {
			if (res.code === 200) {
				proParamsData.forEach(item => {
					if (item.id === row.cate_id) {
						setProItemParams(item.params)
						setInitSpecData(res.data)
						setSpectData([])
					}
				});
			}
		}).catch(err => {
			console.log(err)
		})
		setDrawerConfig({ ...drawerConfig, title: langConfig.g_edit, visible: true });
	};
	const handleEditorChange = (val) => {
		setEditorData(val)
	}
	const drawerCloseHandle = () => {
		setDrawerConfig({ ...drawerConfig, visible: false });
	};
	const [form] = Form.useForm();
	const onFinish = (values) => {
		// 价格是否都已填写
		// let _flag = true
		// specData.forEach((item, key) => {
		// 	if (!item.price) {
		// 		_flag = false
		// 		message.error("所有规格的价格必填，请检查");
		// 		return false;
		// 	}
		// })
		// if (!_flag) {
		// 	return false;
		// }
		// let thumb_id = "";
		// if (thumbData.length > 0) {
		// 	thumb_id = thumbData[0].id;
		// }
		let _spec = []
		if (specData.length > 0) {
			_spec = specData;
		} else {
			let _item;
			initSpecData.forEach(item => {
				_item = { id: item.id, thumbs: item.thumbs, part_no: item.part_no, sort: item.sort }
				item.order_params.forEach(item_params => {
					_item[item_params] = item[item_params]
				})
				_spec.push(_item)
			})
		}

		// 关联商品
        let relation_pro = []
        relateProductData.forEach(item=>{
            relation_pro.push(item.id)
        })
		let thumbs = null
		let thumbs_arr = []
		if(thumbData.length >0){
			thumbData.forEach(item=>{
				thumbs_arr.push(item.id)
			})
			thumbs = thumbs_arr;
		}

		productHandle({ ...values, is_sale: values.is_sale ? 1 : 2, is_show_price: values.is_show_price ? 1 : 2, id: drawerData.id || "",pro_desc:editorData,thumbs, spec: _spec,relation_pro }).then(res => {
			setDrawerConfig({ ...drawerConfig, visible: false })
			getData();
			message.info(res.msg)
		}).catch(err => {
			console.log(err)
		})
	};
	const proCateChangeHandler = (val, label, extra) => {
		if (!val) {
			setProItemParams([])
			return false;
		}
		proParamsData.forEach(item => {
			if (item.id === val) {
				setProItemParams(item.params)
				setInitSpecData([])
			}
		});
	}
	// 处理子组件传来的规格
	const parHandleSpec = (data) => {
		setSpectData([...data])
	}

	const layout = {
		labelCol: {
			span: 5,
		},
		wrapperCol: {
			span: 18,
		},
	};

	// 关联商品对话框
    const relateProKey = useRef(null);
    const [modalRelateConfig, setRelateModalConfig] = useState({
        title: '',
        visible: false
    });
    const showRelateModal = () =>{
        relateProKey.current = nanoid()
        setRelateModalConfig({
            title: '选择关联商品',
            visible: true
        })
    }
    const handleRelateModalOk = async () => {
        if(relateProductDataTmp.length === 0){
            message.error("至少选中一个关联商品！")
            return ;
        }
        setRelateProductData([...relateProductDataTmp])
        setRelateModalConfig({
            title: '',
            visible: false
        })
    };
    const handleRelateModalCancel = () => {
        setRelateModalConfig({ visible: false })
    };
	// 关联商品
    const [relateProductDataTmp, setRelateProductDataTmp] = useState([])
    // 处理子组件传过来的事件
    const handleProductSelected = (data) =>{
        if(relateProductDataTmp.length === 0){
            setRelateProductDataTmp(data);
        }else{
            // 过滤重复添加的关联商品
            let _dataTmp = [...relateProductDataTmp];
            let _dataTmpIds = [];
            _dataTmp.forEach(item=>{
                _dataTmpIds.push(item.id);
            })
            data.forEach(item=>{
                if(!_dataTmpIds.includes(item.id)){
                    _dataTmp.push(item)
                }
            })
            setRelateProductDataTmp(_dataTmp);
        }

    }
    const [relateProductData, setRelateProductData] = useState([])
    const handleDelRelateProduct = (id) =>{
        let _data = [];
        relateProductData.forEach((item)=>{
            if(item.id !== id){
                _data.push(item)
            }
        })
        let _dataTmp = [];
        relateProductDataTmp.forEach((item)=>{
            if(item.id !== id){
                _dataTmp.push(item)
            }
        })
        setRelateProductData([..._data])
        setRelateProductDataTmp([..._dataTmp])
    }

	// 导入excel
    const handleFileUpload = () => {
		refDom.current.click();
	}
	const fileUploadChange = (event) => {
        event.preventDefault()
        // console.log("event.target.files",event.target.files)
        if(event.target.files){
            let file = event.target.files[0]
            if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
                message.error("文件类型错误！");
                return false;
            }
            setModalExcelFields({file})
        }

	}
    const [modalExcelConfig, setModalExcelConfig] = useState({
        title: '',
        visible: false
    });
    const [modalExcelFields, setModalExcelFields] = useState({});
    const [formModalExcel] = Form.useForm();
    const showExcelModal = () => {
        refExcelId.current = nanoid();
        refDom.current = nanoid();

        setModalExcelFields({file:''})
        formModalExcel.setFieldsValue({category_id:[],type_id:undefined,})
        setModalExcelConfig({ title: langConfig.pro_btn_import, visible: true })
    }
    const handleModalExcelOk = async () => {
        let formres = await formModalExcel.validateFields()
		if(!formres.cate_id){
			return false;
		}
        if(modalExcelFields.file==""){
            message.error("请选择上传文件！")
            return false;
        }
        message.info('文件上传中...')
        let fd = new FormData()
        fd.append("file",modalExcelFields.file);
        fd.append("cate_id",formres.cate_id);
        productImport(fd).then(res => {
            if(res.code === 200){
                setModalExcelConfig({ title: langConfig.pro_btn_import, visible: false })
                getData();
                setModalExcelFields({file:''})
                message.success(res.msg)
            }else{
                message.error(res.msg)
            }
        })
    };

    const handleDownTpl = async () => {
		let formres = await formModalExcel.validateFields()
		if(!formres.cate_id){
			return false;
		}
        productExportTpl({cate_id:formres.cate_id }).then(res => {
            if(res.code === 200){
                let url = res.data;
                const a = document.createElement('a')
                a.href = url
                a.setAttribute("download", res.data); // 添加downLoad属性
                a.setAttribute("target", "_blank");
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click() // 点击下载
                document.body.removeChild(a) // 下载完成移除元素
            }
        }).catch(err => {

        })
    }
    const handleModalExcelCancel = () => {
        setModalExcelConfig({ visible: false })
    };

	// 文件管理配置
	const [fileBoxConfig, setFileBoxConfig] = useState({ visible: false, max_select_count: 4, width: 1000, fileboxKey: nanoid() })
	const fileBoxHanlde = (obj) => {
		if (obj.type === 'ok') {
			if(thumbData.length + obj.data.length > fileBoxConfig.max_select_count){
				message.error("最多总共4张，请检查")
				return false;
			}
			setThumbData([...thumbData,...obj.data])
		}
		setFileBoxConfig({ ...fileBoxConfig, visible: false });
	}
	const showUploadThumbBox = () => {
		setFileBoxConfig({ ...fileBoxConfig, visible: true, fileboxKey: nanoid() });
	}
	const handleDelThumb = (obj) => {
		setThumbData(thumbData.filter((item => item !== obj)))
	}
	const [thumbData, setThumbData] = useState([])
	const uploadThumbCom = () => {
		if (thumbData.length < fileBoxConfig.max_select_count) {
			let tmp = thumbData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
			})
			return (<>{tmp}<li className='my-upload-box' onClick={() => { showUploadThumbBox() }}><PlusOutlined /></li></>)
		} else {
			return thumbData.map((item, index) => {
				return <li key={item.id}><a href={item.file_path} target="_blank" rel="noreferrer"><img src={item.file_path} alt="" /></a><div className='action-box' onClick={() => { handleDelThumb(item) }}>{langConfig.g_del}</div></li>
			})
		}
	}

	return (
		<div>
			<div className='breaccrumb-box'>
				<div className='breaccrumb-left'><span>{langConfig.pro_bread_one} / </span>{langConfig.pro_bread_two}</div>
				<div className='breaccrumb-right'>
					<AuthBtn button='admin:product:import'>
						<Button type="primary" size='small' ghost onClick={() => showExcelModal()}> <FileExcelOutlined />{langConfig.pro_import}</Button>
					</AuthBtn>&nbsp;
					<AuthBtn button='admin:product:save'>
						<Button type="primary" size='small' onClick={() => drawerAddHandle()}> <PlusOutlined />{langConfig.g_add}</Button>
					</AuthBtn>
				</div>
			</div>

			<div className='query-form-box'>
				<Form layout="inline" form={formQuery} onFinish={onQueryFinish}>
					<Form.Item name="cate_id" label="">
						<TreeSelect placeholder={langConfig.pro_cate} allowClear treeData={cateData} style={{ width: '150px' }} treeDefaultExpandAll />
					</Form.Item>
					<Form.Item name="is_sale">
						<Select allowClear placeholder={langConfig.pro_is_sale} style={{ width: '135px' }}>
							{
								saleText.map(item => {
									return <Option key={item.value} value={item.value}>{item.label}</Option>
								})
							}
						</Select>
					</Form.Item>
					<Form.Item name="is_verify">
						<Select allowClear placeholder={langConfig.g_verify} style={{ width: '100px' }}>
						{
								verifyText.map(item => {
									return <Option key={item.value} value={item.value}>{item.label}</Option>
								})
							}
						</Select>
					</Form.Item>
					<Form.Item name="keyword" label="">
						<Input allowClear placeholder={langConfig.g_title} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" ghost>
							<SearchOutlined />
							{langConfig.g_search}
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Table size='small' rowKey='id' columns={tableColumns} dataSource={tableData} pagination={false} loading={loading} />
			<div className="page-box">
				<Pagination current={pageInfo.page} total={pageInfo.total} pageSize={pageInfo.page_size} onChange={onPageChange} />
			</div>

			<Drawer title={drawerConfig.title} placement="right" maskClosable={false} width={drawerConfig.width} visible={drawerConfig.visible} onClose={drawerCloseHandle} >

				<Tabs defaultActiveKey="1">
					<TabPane tab={langConfig.pro_tab_info} key="1" forceRender={true}>
						<Form {...layout} form={form} onFinish={onFinish} layout="inline" className="my-inline-form">
							<Form.Item name="title" label={langConfig.pro_form_pro_title}
								rules={[
									{
										required: true,
										message: ''
									},
								]}
							>
								<Input allowClear />
							</Form.Item>
							<Form.Item name="cate_id" label={langConfig.pro_cate}
								rules={[
									{
										required: true,
										message: ''
									},
								]}
							>
								<TreeSelect disabled={drawerData.id ? true : false} allowClear treeData={cateData} treeDefaultExpandAll onChange={proCateChangeHandler} />
							</Form.Item>
							<Form.Item label={langConfig.g_thumb}>
								<div className='myupload-box-list'>
									{uploadThumbCom()}
								</div>
							</Form.Item>
							<Form.Item name="is_sale" label={langConfig.pro_is_sale} valuePropName="checked">
								<Switch  defaultChecked />
							</Form.Item>
							<div style={{ padding: '45px 0 0 15px',width:"970px" }}>
								<SpecTableCom initSpecData={initSpecData} proItemParams={proItemParams} parHandleSpec={parHandleSpec}></SpecTableCom>
							</div>
							<div style={{ height: '140px', width: "100%", marginTop: "5px" }}>
								<Form.Item name="introduction" label={langConfig.pro_form_intro}>
									<Input.TextArea allowClear placeholder='' rows={5} style={{ width: "880px" }} />
								</Form.Item>
							</div>
							<div style={{ width: "980px", margin: "0", display: "flex" }}>
								<div style={{ width: "87px", textAlign: "right" }}>{langConfig.pro_form_desc}：</div>
								<div style={{ width: "880px" }}><Editor key={editorRef.current} value={initEditorData} onChange={handleEditorChange} /></div>
							</div>
							<div style={{ height: '140px', width: "100%", marginTop: "15px" }}>
								<Form.Item name="pro_adv" label={langConfig.pro_form_adv}>
									<Input.TextArea allowClear placeholder='' rows={5} style={{ width: "880px" }} />
								</Form.Item>
							</div>
							<div style={{ height: '140px', width: "100%" }}>
								<Form.Item name="pro_app" label={langConfig.pro_form_app}>
									<Input.TextArea allowClear placeholder='' rows={5} style={{ width: "880px" }} />
								</Form.Item>
							</div>
							<div style={{ marginTop: '10px', width: "100%", padding: "0px 0 0 87px" }}>
								<Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
							</div>
						</Form>
					</TabPane>
					<TabPane tab={langConfig.pro_tab_high} key="2" forceRender={true}>
						<Form {...layout} form={form} onFinish={onFinish} layout="inline" className="my-inline-form">
							{/* <Form.Item name="seo_title" label="SEO标题">
								<Input allowClear placeholder='' />
							</Form.Item>
							<Form.Item name="v_sale_count" label="显示销量">
								<Input allowClear placeholder='' />
							</Form.Item> */}
							<Form.Item name="seo_keyword" label={langConfig.g_keyword}>
								<Input.TextArea allowClear placeholder='' rows={3} />
							</Form.Item>
							<Form.Item name="seo_desc" label={langConfig.g_desc}>
								<Input.TextArea allowClear placeholder='' rows={3} />
							</Form.Item>
							<div style={{ paddingTop: '75px' }}>&nbsp;</div>
							<Form.Item name="sort" label={langConfig.g_sort} tooltip="降序，越大越靠前">
								<Input allowClear />
							</Form.Item>
							<div style={{ marginTop: '10px', width: "100%", padding: "0px 0 0 18px", display: "flex" }}>
								<div className="relate_title">{langConfig.pro_form_relate}：</div>
								<div className="relate_list">
									<div className="relate_btn"><Button type="primary" ghost onClick={showRelateModal}>{langConfig.pro_form_relate_add}</Button></div>
									<div className="relate_li_box">
										{
											relateProductData.map(item=>{
												return <li key={item.id}><span>{item.title}</span> <p><DeleteOutlined onClick={() => { handleDelRelateProduct(item.id) }}/></p></li>
											})
										}
									</div>
								</div>
							</div>
							<div style={{ marginTop: '10px', width: "100%", padding: "20px 0 0 87px" }}>
								<Button type="primary" htmlType="submit">{langConfig.g_save}</Button>
							</div>
						</Form>
					</TabPane>
				</Tabs>


			</Drawer>
			{/* <FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal> */}

			{/* 选择关联商品 */}
			<Modal visible={modalRelateConfig.visible} title={modalRelateConfig.title} onCancel={handleRelateModalCancel} onOk={handleRelateModalOk} maskClosable={false} okText="确认" cancelText="取消" width="850px">
				<ComRelatePro key={relateProKey.current} handleProductSelected={(data) => handleProductSelected(data)}></ComRelatePro>
			</Modal>

			{/* 导入excel数据 */}
            <Modal visible={modalExcelConfig.visible} key={refExcelId.current} title={modalExcelConfig.title} onOk={handleModalExcelOk} onCancel={handleModalExcelCancel} maskClosable={false} okText={langConfig.pro_btn_import} cancelText={langConfig.pro_btn_cancel}>
                <Form form={formModalExcel} labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                    <Form.Item label={langConfig.pro_cate} name="cate_id" rules={[
									{
										required: true,
										message: ''
									},
								]}>
                        <Cascader placeholder=""  options={cateData} />
                    </Form.Item>
                    <Form.Item label={langConfig.pro_upload}>
                        <input ref={refDom} type="file" name='fileUpload' id="fileUpload" onChange={fileUploadChange} multiple style={{display: "none"}} />
                        <Button type="primary" ghost icon={<CloudUploadOutlined />} onClick={handleFileUpload} >{langConfig.pro_upload_select}</Button>
                        <div>{modalExcelFields.file && modalExcelFields.file.name}</div>
                    </Form.Item>
                </Form>
                <div style={{paddingLeft:"15px",textAlign:"right"}}><Button type='link' onClick={() => handleDownTpl()}>{langConfig.pro_down_tpl}</Button></div>
            </Modal>

			<FileBoxModal config={fileBoxConfig} handle={fileBoxHanlde}></FileBoxModal>
		</div>


	)
}

export default Product